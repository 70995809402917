import type { FC } from "react"
import { useTranslation } from "react-i18next"

import { List } from "../../list"
import { Item } from "../item"
import type { ListItem } from "../list.types"
import { Loader } from "../loader"

interface Props {
	loading?: boolean
	items: ListItem[]
	count?: number
}
export const ChallengesList: FC<Props> = ({ loading, count, items }) => {
	const { t } = useTranslation()

	return (
		<List
			loading={loading}
			empty={t("list.empty")}
			loader={<Loader len={count} />}
		>
			{items.slice(0, count ?? items.length).map(item => (
				<Item key={item.id} {...item} />
			))}
		</List>
	)
}
