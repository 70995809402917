import { Divider } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../api/hooks/challenge"
import { Collapse } from "../index"
import { VoteList } from "./vote-list"

interface Props {
	challenge: Challenge
}
export const FullVotes: FC<Props> = ({ challenge }) => {
	const { t } = useTranslation("challenge")

	return (
		<>
			<Divider sx={{ my: 5 }} />
			<Collapse label={t`voteCount`} mountOnEnter>
				<VoteList challenge={challenge} />
			</Collapse>
			<Divider sx={{ my: 5 }} />
		</>
	)
}
