import { Box, Paper } from "@mui/material"
import type { SxProps } from "@mui/system"
import type { FC } from "react"

interface Props {
	overlay: JSX.Element
	overlaySx: SxProps
}
export const Overlay: FC<Props> = ({ overlay, overlaySx }) => (
	<Box
		top={0}
		zIndex={1}
		display='grid'
		position='absolute'
		textAlign='center'
		sx={{
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			placeItems: "center",
		}}
	>
		<Paper
			sx={{
				m: { xs: 3, md: 6 },
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				boxSizing: "border-box",
				width: { xs: "100%", sm: 400, md: 500, lg: 600 },
				maxWidth: { xs: "80%", sm: "100%" },
				px: { xs: 3, sm: 5 },
				py: 5,
				...overlaySx,
			}}
		>
			{overlay}
		</Paper>
	</Box>
)
