import type { Breakpoint, SxProps } from "@mui/material"

import { getImage, useWidth } from "../../../../utils"
import type { ImageConfig } from "../index"

export const useImage = (
	images: ImageConfig,
	minImageHeights: Collection<Breakpoint, number>,
) => {
	const screen = useWidth()

	const sx = Object.entries(images).reduce(
		(acc, [width, image]) => {
			const br = width as Breakpoint
			acc[br] = {
				pt: `${(image.height / image.width) * 100}%`,
				minHeight: minImageHeights[br],
			}
			return acc
		},
		{} as Collection<Breakpoint, SxProps>,
	) as SxProps

	return {
		sx: { "& > div": sx } as SxProps,
		...getImage(images[screen]),
	}
}
