import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

type Reasons = Array<{ title: string; subtitle: string }>
export const Details = () => {
	const { t } = useTranslation("home")
	const reasons: Reasons = t("participate.reasons", { returnObjects: true })

	return (
		<Stack
			py={{ xs: 0, lg: 5 }}
			px={0.6}
			gap={3.75}
			width='min(100%, 500px)'
		>
			{reasons.map(({ title, subtitle }) => (
				<Stack key={title} spacing={0.5}>
					<Typography
						variant='h6'
						component='h3'
						fontSize='1.12rem'
						fontWeight='bold'
					>
						{title}
					</Typography>
					<Typography variant='body2'>{subtitle}</Typography>
				</Stack>
			))}
		</Stack>
	)
}
