import { joinPath } from "@artists/devkit"
import { Stack } from "@mui/material"
import type { FC, ReactNode } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"

import { getENV, publicPath } from "../../../../@common"

interface Props {
	title: string
	image?: string
	description?: string
	children: ReactNode
}
export const Page: FC<Props> = ({
	title,
	children,
	description = "Spoonflower’s Design Challenges encourage creativity by inviting our community to enter designs regularly around fun themes and vote on favorites to win prizes.",
	image = publicPath("icons/social-preview.jpg"),
}) => {
	const { pathname } = useLocation()
	const url = joinPath(getENV("HOST"), pathname)

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta property='og:url' content={url} />
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:image' content={image} />
			</Helmet>

			<Stack
				width='100%'
				maxWidth='xl'
				mx='auto'
				px={{ xs: 3, md: 5 }}
				mb={15}
			>
				{children}
			</Stack>
		</>
	)
}
