import { Skeleton } from "@mui/material"

export const TitleLoader = () => (
	<>
		<Skeleton sx={{ transform: "none", height: { xs: 42, sm: 54 } }} />
		<Skeleton
			sx={{
				mt: 1,
				mx: "auto",
				width: "60%",
				transform: "none",
				height: { xs: 42, sm: 52 },
			}}
		/>
	</>
)
