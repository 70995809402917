import { EmojiEvents } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../api/hooks/challenge"
import { Loader } from "./loader"
import { PrizeItem } from "./prize"

export interface PrizesProps {
	id?: string
	loading?: boolean
	prizes: Challenge["prizes"]
	description?: JSX.Element
}

export const Prizes = ({ loading, prizes, id, description }: PrizesProps) => {
	const { t } = useTranslation("common")

	return (
		<Stack id={id}>
			<Stack direction='row' gap={2} mx='auto'>
				<EmojiEvents sx={{ color: "#ECB201" }} fontSize='large' />
				<Typography variant='h4Serif' component='h2'>
					{t("prizes.title")}
				</Typography>
			</Stack>
			{description}
			<Stack spacing={2.5} mt={5}>
				{loading ? (
					<Loader />
				) : (
					prizes.map(prize => (
						<PrizeItem key={prize.places.join()} prize={prize} />
					))
				)}
			</Stack>
		</Stack>
	)
}
