import { grey } from "@mui/material/colors"
import type { SxProps } from "@mui/system"

export const richTextSx: SxProps = {
	"& a": {
		color: "inherit",
		textDecoration: "underline",
		display: "inline",
		"&:hover, &:focus": { color: grey[600] },
	},
	"> *": {
		margin: 0,
		"&:not(:last-child)": {
			mb: 2,
		},
	},
}
