import {
	Card,
	CardContent,
	CardMedia,
	CardSubTitle,
	CardTitle,
} from "@artists/uikit"
import { Link } from "@mui/material"
import type { FC } from "react"
import { Trans, useTranslation } from "react-i18next"

import type { Entry } from "../../../../../../api/hooks/challenge"
import { designerLink } from "../../../../../../utils"
import { ActionCard, Favorite, useActionStatuses } from "../../../../../@shared"

interface Props {
	entry: Entry
	onPdpClick: () => void
}
export const TopCard: FC<Props> = ({ entry, onPdpClick }) => {
	const { t } = useTranslation("challenge")
	const action = useActionStatuses(entry)

	if (action) return <ActionCard {...action} />

	return (
		<Card>
			<Link target='_blank' href={entry.pdpUrl}>
				<CardMedia
					image={entry.entryImage}
					label={t("place", {
						count: entry?.entryPlace ?? 0,
						ordinal: true,
					})}
				/>
			</Link>

			<CardContent>
				<CardTitle>
					<Link
						target='_blank'
						href={entry.pdpUrl}
						onClick={onPdpClick}
						underline='hover'
					>
						{entry.entryTitle}
					</Link>
				</CardTitle>

				<CardSubTitle>
					<Trans
						ns='challenge'
						i18nKey='byDesigner'
						values={{ designer: entry.user.userName }}
					>
						<Link
							mr='auto'
							target='_blank'
							underline='hover'
							color='inherit'
							href={designerLink(entry.user.userName)}
						/>
					</Trans>
				</CardSubTitle>
			</CardContent>

			<Favorite entry={entry} sx={{ m: 1, mt: -1 }} />
		</Card>
	)
}
