import { challengeDetailsIds } from "@artists/challenges-testkit"
import { Button, Skeleton, Typography } from "@mui/material"
import type { FC } from "react"

import type { Challenge } from "../../../../../api/hooks/challenge"
import { Time } from "../../../../@shared"
import { TitleLoader } from "../styles"
import { useOverlay } from "./overlay.hook"

interface Props {
	loading?: boolean
	challenge?: Challenge
}
export const Overlay: FC<Props> = ({ loading, challenge }) => {
	const { challengeName, title, subtitle, button } = useOverlay(challenge)

	return (
		<>
			<Typography component='h1' width='100%'>
				<Typography
					component='span'
					display='block'
					mx='auto'
					sx={{ width: loading ? "60%" : "100%", mb: 1 }}
				>
					{loading ? <Skeleton width='100%' /> : title}
				</Typography>

				<Typography
					variant='h4Serif'
					component='span'
					fontSize='2.25em'
					data-testid={challengeDetailsIds.title}
					sx={{ width: loading ? "80%" : "100%" }}
				>
					{loading ? <TitleLoader /> : challengeName}
				</Typography>
			</Typography>

			{loading ? (
				<Skeleton
					sx={{ transform: "none", width: 175, height: 38, my: 3 }}
				/>
			) : (
				<Button
					data-testid={challengeDetailsIds.CTAButton("")}
					sx={{ my: 3 }}
					variant='contained'
					{...button}
				/>
			)}

			<Typography
				fontWeight='bold'
				variant='body2'
				component='p'
				color={subtitle.color}
				width={loading ? "80%" : "100%"}
			>
				{loading ? (
					<Skeleton />
				) : "text" in subtitle ? (
					subtitle.text
				) : (
					<>
						{subtitle.label}&nbsp;
						<Time data-testid={challengeDetailsIds.timeLine}>
							{subtitle.time}
						</Time>
					</>
				)}
			</Typography>
		</>
	)
}
