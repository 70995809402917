import { submissionIds } from "@artists/challenges-testkit"
import { Image } from "@artists/uikit"
import { Box, Button, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { Link } from "react-router-dom"

export interface NotFoundProps {
	title: string | JSX.Element
	buttonText: string
	to: string
	state?: Collection<string, unknown>
	image?: string
}

export const NotFound: FC<NotFoundProps> = ({
	title,
	buttonText,
	state,
	image,
	to,
}) => (
	<Box
		mt={10}
		mx='auto'
		rowGap={3}
		columnGap={7}
		display='grid'
		alignItems='center'
		maxWidth={{ xs: 320, md: "100%" }}
		gridTemplateColumns={{ md: "320px 1fr" }}
	>
		{image && (
			<Image
				src={image}
				style={{ width: 320, height: 320, margin: "auto" }}
			/>
		)}

		<Stack flex={1} maxWidth={600}>
			<Typography
				data-testid={submissionIds.errorMessage}
				typography={{ xs: "h6", md: "h5" }}
				component='h1'
			>
				{title}
			</Typography>

			<Button
				to={to}
				state={state}
				component={Link}
				variant='contained'
				sx={{ mr: "auto", mt: { xs: 4, md: 3 } }}
				data-testid={submissionIds.goHomeButton}
			>
				{buttonText}
			</Button>
		</Stack>
	</Box>
)
