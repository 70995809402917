import type { FC } from "react"

import type {
	CurrentChallenges,
	Partners as TPartners,
	PastChallenges,
	Prizes as PrizesList,
} from "../../api/hooks/home"
import {
	Challenges,
	Header,
	Participate,
	Partners,
	Prizes,
	Shop,
} from "./components"
import { getVoteChallenge } from "./home.utils"

interface Props {
	loading: boolean
	current: CurrentChallenges
	past: PastChallenges
	prizes: PrizesList
	partners: TPartners
}

export const Home: FC<Props> = ({
	loading,
	current,
	partners,
	past,
	prizes,
}) => {
	const vote = getVoteChallenge(current)
	const winner = past[0]

	return (
		<>
			<Header loading={loading} challenges={{ vote, winner }} />
			<Challenges loading={loading} challenges={current} />
			<Participate />
			<Prizes loading={loading} prizes={prizes} />
			<Shop loading={loading} challenges={past} />
			<Partners loading={loading} partners={partners} />
		</>
	)
}
