import type { MutableRefObject } from "react"

interface Props {
	scrollTo: number
	total: number
	itemRef: MutableRefObject<HTMLElement | undefined>
	listRef: MutableRefObject<HTMLElement | undefined>
}
export const useListScroll = ({ scrollTo, total, listRef, itemRef }: Props) => {
	const isLast6 = total - scrollTo <= 6

	const scrollToItem = () => {
		if (!listRef.current) return

		const root = document.documentElement
		const { top, height } = listRef.current.getBoundingClientRect()
		const prop = isLast6 ? "scrollPaddingBottom" : "scrollPaddingTop"
		const offset = isLast6 ? window.innerHeight - height - top : top
		const block = isLast6 ? "end" : "start"

		root.style[prop] = `${offset}px`
		itemRef.current?.scrollIntoView({ behavior: "smooth", block })

		requestAnimationFrame(() => {
			root.style[prop] = ""
		})
	}

	const getItemRef = (index: number) => {
		if (isLast6 && index === total - 1) return itemRef
		else if (!isLast6 && index === scrollTo) return itemRef
		return undefined
	}

	return { getItemRef, scrollToItem }
}
