import { Box } from "@mui/material"
import type { FC } from "react"

import type { Challenge } from "../../../../../api/hooks/home"
import { Actions } from "./actions"
import { How } from "./how"

interface Props {
	loading: boolean
	challenges: Partial<Collection<"vote" | "winner", Challenge>>
}
export const Info: FC<Props> = props => (
	<Box
		mb={9}
		gap={5}
		display='grid'
		component='section'
		gridTemplateColumns={{ lg: "repeat(2, 1fr)" }}
	>
		<Actions {...props} />
		<How />
	</Box>
)
