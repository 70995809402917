import type { TFunction } from "i18next"

import type { Challenge } from "../../../../api/hooks/home"
import { getImage, getStatus } from "../../../../utils"
import type { ListItem } from "../../../@shared/challenges"

export const normalizeChallenges = (
	t: TFunction<"common">,
	challenges: Challenge[],
): ListItem[] =>
	challenges.map(c => ({
		id: c.id,
		slug: c.slug,
		title: c.name,
		status: c.status.current.name,
		subtitle: getStatus(t, c.status.next),
		image: getImage(c.images.thumbnail320x320),
	}))
