import { Image } from "@artists/uikit"
import { Box } from "@mui/material"
import type { FC } from "react"

interface Props {
	image: string | { src: string; lowSrc?: string }
}
export const Media: FC<Props> = ({ image }) => {
	const imageProps = typeof image === "string" ? { src: image } : image

	return (
		<Box overflow='hidden'>
			<Box
				className='card-image'
				sx={({ transitions }) => ({
					transition: transitions.create("transform", {
						duration: transitions.duration.complex,
					}),
				})}
			>
				<Image {...imageProps} style={{ paddingTop: "100%" }} />
			</Box>
		</Box>
	)
}
