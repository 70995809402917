import { Divider, Stack } from "@mui/material"
import type { FC, ReactNode } from "react"

interface Props {
	children: ReactNode
	divider?: boolean
}
export const Container: FC<Props> = ({ children, divider = false }) => (
	<Stack
		spacing={4}
		divider={divider && <Divider />}
		width='100%'
		maxWidth={800}
		pt={8}
		m='auto'
	>
		{children}
	</Stack>
)
