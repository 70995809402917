const pr = new Intl.PluralRules("en-US", {
	type: "ordinal",
})

const suffixes = new Map([
	["one", "st"],
	["two", "nd"],
	["few", "rd"],
	["other", "th"],
])

export const formatOrdinals = (n: number) => {
	const rule = pr.select(n)
	const suffix = suffixes.get(rule)
	return `${n}${suffix}`
}
