import { homeChallengesIds } from "@artists/challenges-testkit"
import { Box, Button, Link, Stack, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

export const How = () => {
	const { t } = useTranslation("home")
	const items = t("how.items", { returnObjects: true })

	return (
		<Box
			py={7}
			px={2}
			display='grid'
			overflow='hidden'
			borderRadius='5px'
			sx={{ placeContent: "center" }}
			boxShadow='2px 2px 8px 0 rgba(51,51,51,0.15)'
		>
			<Stack spacing={2}>
				<Typography component='h2' variant='h5'>
					{t`how.title`}
				</Typography>
				<Stack component='ul' spacing={2} sx={{ m: 0, p: "0 14px" }}>
					{items.map((item, index) => (
						<Typography component='li' variant='body2' key={index}>
							{item}
						</Typography>
					))}

					<Typography component='li' variant='body2'>
						<Trans ns='home' i18nKey='how.last'>
							<Link component={RouterLink} to='#shop' />
						</Trans>
					</Typography>
				</Stack>
			</Stack>

			<Button
				data-testid={homeChallengesIds.howToEnter}
				size='small'
				href='#challenges'
				variant='contained'
				sx={{ textAlign: "center", mt: 4 }}
			>
				{t`how.btn`}
			</Button>
		</Box>
	)
}
