import { Divider } from "@mui/material"
import type { FC } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import {
	type Challenge,
	useTopChallengeResults,
} from "../../../../api/hooks/challenge"
import { Root } from "../../../../utils"
import { Top } from "./top"
import { Winner } from "./winner"

interface Props {
	loading?: boolean
	challenge?: Challenge
}
export const Results: FC<Props> = ({ loading: pageLoading, challenge }) => {
	const { slug } = useTypedParams(Root.Challenge)
	const { challengeResults, loading } = useTopChallengeResults(
		slug,
		challenge,
	)

	return (
		<>
			<Winner
				loading={loading || pageLoading}
				winner={challengeResults?.winner}
				partner={challenge?.partner?.name ?? "Spoonflower"}
			/>
			<Divider sx={{ py: 5 }} />
			<Top
				loading={loading || pageLoading}
				searchLink={challenge?.searchUrl}
				productName={challenge?.product.name}
				entries={challengeResults?.topChallengeEntries ?? []}
				top={challengeResults?.topDesignsCount}
			/>
			<Divider sx={{ my: 5 }} />
		</>
	)
}
