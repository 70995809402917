import { homeChallengesIds } from "@artists/challenges-testkit"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../api/hooks/challenge"
import { getPlace } from "./getPlace"
import { PrizeBullet } from "./prize-bullet"

interface Props {
	prize: NonNullable<Challenge["prizes"]>[number]
}

export const PrizeItem = ({ prize }: Props) => {
	const { t } = useTranslation("common")
	const title = getPlace(prize.places)
	const isExtra = !prize.places[0]

	const isLargeText = title?.length < 4

	return (
		<Stack
			data-testid={homeChallengesIds.prize(title)}
			direction='row'
			border='3px solid #eee'
			padding={2.5}
			borderRadius='4px'
		>
			<Stack
				component='h3'
				ml={-2.5}
				width='35%'
				minWidth='35%'
				justifyContent='center'
				borderRight='1px solid #eee'
			>
				<Typography
					variant={isLargeText ? "h4" : "h6"}
					component='span'
					fontWeight='bold'
					mb={0.6}
					textAlign='center'
				>
					{title}
				</Typography>
				{!isExtra && (
					<Typography fontWeight='bold' textAlign='center'>
						{t("prizes.place")}
					</Typography>
				)}
			</Stack>
			<Stack flexGrow='1' justifyContent='center' component='ul'>
				{prize.prizes.map(item => (
					<PrizeBullet key={item} prizeText={item} />
				))}
			</Stack>
		</Stack>
	)
}
