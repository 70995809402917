import { assertIsDefined } from "@artists/devkit"
import type { ProductName } from "@artists/gql/design-challenges"
import { Button, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "../../../../../api/hooks/@shared"
import type { Entry } from "../../../../../api/hooks/challenge"
import {
	AnalyticsPageTitle,
	createProductClickAnalytics,
	useAnalytics,
} from "../../../../../utils"
import { List } from "../../../../@shared"
import { TopCard } from "./card"
import { Loader } from "./loader"

interface Props {
	top: Maybe<number>
	loading?: boolean
	entries: Entry[]
	searchLink?: string
	productName?: ProductName
}

export const Top: FC<Props> = ({
	loading,
	searchLink,
	top,
	entries,
	productName,
}) => {
	const { t } = useTranslation("challenge")
	const { send } = useAnalytics()
	const { user } = useAuth()

	const sendAnalytics = useCallback(
		(entry: Entry, index: number) => () => {
			assertIsDefined(productName)

			send(
				createProductClickAnalytics({
					userId: user?.userId ?? null,
					designId: entry.designId,
					pageTitle: AnalyticsPageTitle.Vote,
					index,
					link: entry.pdpUrl,
					authorName: entry.user.userName,
					productName,
				}),
			)
		},
		[productName, send, user?.userId],
	)

	return (
		<Stack mt={8}>
			<Typography
				variant='h4Serif'
				component='h2'
				mb={4}
				textAlign='center'
			>
				{t("topTitle", { top })}
			</Typography>

			<List loading={loading} loader={<Loader />}>
				{entries.map((entry, index) => (
					<TopCard
						key={entry.id}
						entry={entry}
						onPdpClick={sendAnalytics(entry, index)}
					/>
				))}
			</List>

			<Button
				target='_blank'
				variant='outlined'
				href={searchLink ?? ""}
				disabled={loading || !searchLink}
				sx={{ alignSelf: "center", mt: 5 }}
			>
				{t("shopMore")}
			</Button>
		</Stack>
	)
}
