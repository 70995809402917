import type { FC } from "react"

import type { NormalizedChallenge } from "../schema"
import { Card, Content, Media } from "./card"

interface Props {
	data: NormalizedChallenge
}
export const Action: FC<Props> = ({ data }) => {
	if (!data) return null
	const { image, state, title, description, link, subtitle, disabled } = data

	return (
		<Card to={link} state={state}>
			<Media image={image} />

			<Content
				title={title}
				subtitle={subtitle}
				disabled={disabled}
				description={description}
			/>
		</Card>
	)
}
