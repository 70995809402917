import { Divider, Skeleton, Stack, Typography } from "@mui/material"

export const Loader = () => (
	<>
		{[...Array(5).keys()].map(i => (
			<Stack
				p={2}
				key={i}
				gap={2}
				py={2.5}
				direction='row'
				borderRadius='4px'
				alignItems='center'
				border='3px solid #eee'
				divider={<Divider sx={{ height: 80, borderWidth: 1 }} />}
			>
				<Stack
					alignItems='center'
					flexBasis={1}
					px={{ xs: 1, sm: 3, md: 5 }}
				>
					<Typography variant='h3' width={100}>
						<Skeleton />
					</Typography>
					<Typography width={45} mt={-1}>
						<Skeleton />
					</Typography>
				</Stack>

				<Stack width='100%'>
					<Typography>
						<Skeleton />
					</Typography>
					<Typography>
						<Skeleton />
					</Typography>
					<Typography width='35%'>
						<Skeleton />
					</Typography>
				</Stack>
			</Stack>
		))}
	</>
)
