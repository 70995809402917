import { formatOrdinals } from "@artists/devkit"

// TODO: revisit when a solution for backend data i18n is in place
const EN_DASH = "\u2013"

export function getPlace(places: number[]) {
	const [from, to] = places

	if (from && to) {
		return `${formatOrdinals(from)}${EN_DASH}${formatOrdinals(to)}`
	}

	if (from && !to) {
		return `${formatOrdinals(from)}`
	}

	return `Plus!`
}
