import { homeChallengesIds } from "@artists/challenges-testkit"
import { Box, Link, Typography } from "@mui/material"
import { Trans } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

import type { Prizes as PrizesList } from "../../../../api/hooks/home"
import { Prizes as SharedPrizes } from "../../../@shared/prizes"
import { scrollMarginTop } from "../../style"

interface Props {
	loading?: boolean
	prizes: PrizesList
}

export const Prizes = ({ loading, prizes }: Props) => (
	<Box
		mx='auto'
		id='prizes'
		width='100%'
		marginBottom={9}
		component='section'
		sx={{ scrollMarginTop }}
		maxWidth={{ xs: "none", md: 744 }}
		data-testid={homeChallengesIds.prizesSection}
	>
		<SharedPrizes
			loading={loading}
			prizes={prizes}
			description={<Description />}
		/>
	</Box>
)

const Description = () => (
	<Typography mt={2.5} variant='body2' color='#666' textAlign='center'>
		<Trans ns='home' i18nKey='prizes.intro'>
			<Link component={RouterLink} to='#partners' color='text.primary' />
		</Trans>
	</Typography>
)
