interface Props extends Partial<HTMLImageElement> {
	isLogo?: boolean
	src: string
}
export const Img = ({ isLogo, src }: Props) => {
	const css = isLogo
		? { maxHeight: 128, height: "100%", width: "auto" }
		: { maxWidth: 300, width: "100%", height: "auto" }

	return (
		<img
			alt='partnerLogo'
			loading='lazy'
			style={{ objectFit: "cover", ...css }}
			src={src}
		/>
	)
}
