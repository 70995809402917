import { Image } from "@artists/uikit"
import type { BoxProps, Breakpoint } from "@mui/material"
import { Box } from "@mui/material"
import type { FC } from "react"

import type { ImageConfig } from "../index"
import { useImage } from "./useImage"

interface Props extends BoxProps {
	minImageHeights: Collection<Breakpoint, number>
	images: ImageConfig
}

export const HeroImage: FC<Props> = ({ images, minImageHeights }) => {
	const { sx, ...imageProps } = useImage(images, minImageHeights)

	return (
		<Box sx={sx}>
			<Image {...imageProps} />
		</Box>
	)
}
