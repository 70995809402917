import { KeyboardArrowRight } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import type { FC, ReactElement } from "react"

import { Time } from "../../../../../../../@shared"

interface Props {
	title: string
	subtitle?: string
	disabled?: boolean
	description: ReactElement | string
}

export const Content: FC<Props> = ({
	title,
	subtitle,
	description,
	disabled,
}) => (
	<>
		<Stack
			overflow='hidden'
			sx={({ breakpoints }) => ({ [breakpoints.down(530)]: { px: 2 } })}
		>
			<Typography
				variant='h5'
				component='h2'
				sx={({ breakpoints }) => ({
					mb: 1,
					color: disabled ? "text.disabled" : "text.primary",
					[breakpoints.down(530)]: { fontSize: "1.05rem" },
				})}
			>
				{subtitle ? (
					<>
						{title} <Time>{subtitle}</Time>
					</>
				) : (
					title
				)}
			</Typography>

			<Typography
				color={disabled ? "text.disabled" : "warning.dark"}
				sx={{ lineHeight: 1.25 }}
			>
				{description}
			</Typography>
		</Stack>

		<KeyboardArrowRight
			sx={({ breakpoints }) => ({
				[breakpoints.down(430)]: { display: "none" },
			})}
			fontSize='large'
		/>
	</>
)
