import type { Breakpoint } from "@mui/material"
import { Skeleton } from "@mui/material"
import type { FC } from "react"

import type { ImageConfig } from "../index"
import { HeroImage } from "./image"

interface Props {
	minImageHeights: Collection<Breakpoint, number>
	images?: ImageConfig
}

export const BgImage: FC<Props> = ({ images, minImageHeights }) => {
	if (!images) {
		return (
			<Skeleton
				sx={{
					width: "100%",
					transform: "none",
					height: minImageHeights,
				}}
			/>
		)
	}

	return <HeroImage images={images} minImageHeights={minImageHeights} />
}
