import { useTranslation } from "react-i18next"

export const useMenu = () => {
	const { t } = useTranslation("home")

	return [
		{ link: "#challenges", text: t("nav.challenges") },
		{ link: "#why", text: t("nav.why") },
		{ link: "#prizes", text: t("nav.prizes") },
		{ link: "#shop", text: t("nav.shop") },
	]
}
