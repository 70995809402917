import { Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import { YouTube } from "./youtube"

interface Props {
	loading?: boolean
	videoID: string
}
export const Player: FC<Props> = ({ loading, videoID }) => {
	const { t } = useTranslation("challenge")

	return (
		<Stack spacing={3}>
			<Typography textAlign='center' variant='h4Serif' component='h2'>
				{t("player.title")}
			</Typography>

			<YouTube loading={loading} videoID={videoID} />
		</Stack>
	)
}
