import { challengeDetailsIds } from "@artists/challenges-testkit"
import type {
	AcceptableRepeat,
	ProductName,
} from "@artists/gql/design-challenges"
import { ChallengeStatusName } from "@artists/gql/design-challenges"
import { Collapse, Link, List } from "@mui/material"
import type { FC } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

import { displayProductName } from "../../../../../../entities/products"
import { getRepeatsText } from "../../../../../../entities/repeats"
import { format } from "../../../../../../utils"
import { Time } from "../../../../../@shared"

interface Props {
	isOpen: boolean
	product: ProductName
	acceptableRepeats: AcceptableRepeat[]
	entriesClose: string
	challengeStatus: ChallengeStatusName
	topCount: number
	termsLink: string
}

export const More: FC<Props> = ({
	isOpen,
	product,
	challengeStatus,
	acceptableRepeats,
	entriesClose,
	topCount,
	termsLink,
}) => {
	const { t } = useTranslation("challenge")
	const date = format(entriesClose, "eee, MMM d")
	const time = format(entriesClose, "ha z")
	const isResultsPublic =
		challengeStatus === ChallengeStatusName.ResultsPublic
	const pointerEvents = isResultsPublic ? "none" : "auto"

	return (
		<Collapse data-testid={challengeDetailsIds.viewMore} in={isOpen}>
			<List
				sx={{
					listStyle: "disc",
					paddingLeft: "15px",
					"& li": { mb: 3 },
				}}
			>
				<li>
					<Trans
						ns='challenge'
						i18nKey='details.timeline'
						values={{ date, time }}
					>
						<Time children={undefined} />
						<Link
							to='#timeline'
							component={RouterLink}
							sx={{ pointerEvents }}
						/>
					</Trans>
				</li>
				<li>
					{t("details.productAndRepeats", {
						product: displayProductName(product),
						count: acceptableRepeats.length,
						repeats: getRepeatsText(acceptableRepeats),
					})}
				</li>
				<li>{t("details.restrictionsOnDesigns")}</li>
				<li>{t("details.votingProcess", { topCount })}</li>
				<li>
					<Trans
						ns='challenge'
						i18nKey='details.prizesList'
						values={{ topCount }}
					>
						<Link
							to='#prizes'
							component={RouterLink}
							sx={{ pointerEvents }}
						/>
					</Trans>
				</li>
				<li>
					<Trans ns='challenge' i18nKey='details.terms'>
						<Link href={termsLink} />
					</Trans>
				</li>
			</List>
		</Collapse>
	)
}
