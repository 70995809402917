import { Chip, Link, Link as MuiLink, Stack, Typography } from "@mui/material"
import { forwardRef } from "react"
import { Trans, useTranslation } from "react-i18next"

import type { MinimalEntry } from "../../../../../api/hooks/challenge"
import { designerLink } from "../../../../../utils"
import { Card } from "../styles"
import { getFontSize } from "./font.util"

interface Props {
	active?: boolean
	inView?: boolean
	entry: MinimalEntry
}
export const Entry = forwardRef(({ entry, active, inView }: Props, ref) => {
	const { t } = useTranslation("challenge")

	return (
		<Card ref={ref} active={active} height={76}>
			{inView && (
				<>
					<Typography
						variant='h5'
						component='p'
						textAlign='center'
						color='text.secondary'
						fontSize={getFontSize(Number(entry.entryPlace))}
					>
						{entry.entryPlace}
					</Typography>

					<Stack minWidth={50} alignItems='center'>
						<Chip label={entry.validVotes} />
						<Typography variant='body2' color='text.secondary'>
							{t`votes`}
						</Typography>
					</Stack>

					<Stack overflow='hidden'>
						<Typography color='text.primary' noWrap>
							<Link href={entry.pdpUrl} target='_blank' mr='auto'>
								{entry.entryTitle}
							</Link>
						</Typography>

						<Typography noWrap>
							<Trans
								ns='challenge'
								i18nKey='byDesigner'
								values={{ designer: entry.user.userName }}
							>
								<MuiLink
									target='_blank'
									href={designerLink(entry.user.userName)}
									underline='hover'
									mr='auto'
								/>
							</Trans>
						</Typography>
					</Stack>
				</>
			)}
		</Card>
	)
})
