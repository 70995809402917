import { challengeDetailsIds } from "@artists/challenges-testkit"
import { Skeleton, Typography } from "@mui/material"
import { sanitize } from "isomorphic-dompurify"
import type { FC } from "react"

import { richTextSx } from "../../../../@shared/rich-text"

interface Props {
	loading?: boolean
	intro?: string
}

export const Description: FC<Props> = ({ loading, intro }) => (
	// eslint-disable-next-line react/no-danger-with-children
	<Typography
		component='div'
		textAlign='center'
		flexDirection='column'
		typography={{ xs: "body1", md: "h5" }}
		data-testid={challengeDetailsIds.challengeIntro}
		sx={richTextSx}
		dangerouslySetInnerHTML={
			!loading ? { __html: sanitize(intro || "") } : undefined
		}
	>
		{loading ? (
			<Skeleton
				sx={{ mx: "auto", height: 32, width: "60%", transform: "none" }}
			/>
		) : undefined}
	</Typography>
)
