import { challengeDetailsIds } from "@artists/challenges-testkit"
import { Skeleton, Stack, Typography } from "@mui/material"
import { sanitize } from "isomorphic-dompurify"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../../api/hooks/challenge"
import { richTextSx } from "../../../../@shared/rich-text"
import { Details } from "./details"

interface Props {
	loading?: boolean
	challenge?: Challenge
}

export const Info = ({ loading, challenge }: Props) => {
	const { t } = useTranslation("challenge")

	return (
		<Stack alignItems='center'>
			<Typography variant='h4Serif' component='h2'>
				{t("details.title")}
			</Typography>

			<Typography
				mt={2}
				component='div'
				data-testid={challengeDetailsIds.challengeDetails}
				sx={{ ...richTextSx, width: "100%" }}
				dangerouslySetInnerHTML={
					!loading
						? { __html: sanitize(challenge?.details ?? "") }
						: undefined
				}
			>
				{loading ? (
					<>
						<Skeleton sx={{ width: "80%", mx: "auto" }} />
						<Skeleton sx={{ width: "80%", mx: "auto" }} />
					</>
				) : undefined}
			</Typography>
			{!loading && <Details challenge={challenge} />}
		</Stack>
	)
}
