import { Box, Skeleton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const Loader = () => {
	const [t] = useTranslation("challenge")

	return (
		<Box mt={6}>
			<Stack direction='row' spacing={2} alignItems='center'>
				<Skeleton variant='circular' width={90} height={90} />

				<Stack>
					<Typography variant='h5' width={150}>
						<Skeleton />
					</Typography>

					<Typography width={75}>
						<Skeleton />
					</Typography>
				</Stack>
			</Stack>

			<Stack mt={3} direction='row'>
				<Typography whiteSpace='nowrap'>
					<b>{t`about`}</b>
				</Typography>
				<Skeleton sx={{ ml: 1, width: "100%" }} />
			</Stack>

			<Typography>
				<Skeleton />
				<Skeleton />
				<Skeleton width='60%' />
			</Typography>
		</Box>
	)
}
