import { useQuery } from "@apollo/client"
import { MyCurrentEntryDocument } from "@artists/gql/design-challenges"

import { useAuth } from "../../@shared"

export const useMyEntry = (challengeId: string) => {
	const { authorized } = useAuth()
	const { data, loading } = useQuery(MyCurrentEntryDocument, {
		variables: { challengeId: challengeId },
		skip: !authorized,
	})

	return { myEntry: data?.myActiveChallengeEntry, myEntryLoading: loading }
}
