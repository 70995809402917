import { homeChallengesIds } from "@artists/challenges-testkit"
import { Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { CurrentChallenges } from "../../../../api/hooks/home"
import { ChallengesList } from "../../../@shared/challenges"
import { scrollMarginTop } from "../../style"
import { normalizeChallenges } from "./challenges.schema"

interface Props {
	loading?: boolean
	challenges: CurrentChallenges
}
export const Challenges: FC<Props> = ({ loading, challenges }) => {
	const { t } = useTranslation("home")
	const [commonT] = useTranslation("common")

	return (
		<Stack
			mb={9}
			id='challenges'
			component='section'
			sx={{ scrollMarginTop }}
			data-testid={homeChallengesIds.currentChallenges}
		>
			<Typography
				textAlign='center'
				component='h2'
				variant='h4Serif'
				mb={5}
			>
				{t`challenges.title`}
			</Typography>
			<ChallengesList
				loading={loading}
				items={normalizeChallenges(commonT, challenges)}
			/>
		</Stack>
	)
}
