import { Collapse, Link, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useToggle } from "react-use"

import type { Winner } from "../../../../../../../api/hooks/challenge"

interface Props {
	winner: Winner
}
export const Description: FC<Props> = ({ winner }) => {
	const [open, toggle] = useToggle(false)
	const [t] = useTranslation("challenge")

	const into = winner.entry.entryDescription.split(" ").slice(0, 30).join(" ")
	const rest = winner.entry.entryDescription.split(" ").slice(30).join(" ")

	if (!into) return null

	return (
		<>
			<Typography mt={3}>
				<b>{t`about`}</b> {into}
			</Typography>

			<Collapse in={open}>{rest}</Collapse>

			{rest && (
				<Link onClick={toggle} sx={{ cursor: "pointer" }}>
					{t`more`}
				</Link>
			)}
		</>
	)
}
