import { useTheme } from "@artists/uikit"
import { KeyboardArrowDown } from "@mui/icons-material"
import { Link, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

interface Props {
	isOpen: boolean
	toggle: () => void
}

export const Summary: FC<Props> = ({ isOpen, toggle }) => {
	const {
		theme: {
			transitions: { duration, easing },
		},
	} = useTheme()
	const { t } = useTranslation("challenge")

	return (
		<Link
			color='warning.dark'
			fontWeight='bold'
			sx={{ cursor: "pointer" }}
			onClick={toggle}
		>
			<Stack
				direction='row'
				sx={{ placeContent: { xs: "center", md: "start" } }}
			>
				<Typography fontWeight='bold'>
					{isOpen ? t("details.less") : t("details.more")}
				</Typography>
				<KeyboardArrowDown
					sx={{
						transform: `rotate(${isOpen ? 180 : 0}deg)`,
						transition: `transform ${duration.shorter}ms ${easing.easeIn}`,
					}}
				/>
			</Stack>
		</Link>
	)
}
