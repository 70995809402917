import { Box, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Winner as IWinner } from "../../../../../api/hooks/challenge"
import { DesignCard } from "./card"
import { Designer } from "./designer"

interface Props {
	loading?: boolean
	winner: Maybe<IWinner>
	partner: string
}
export const Winner: FC<Props> = ({ winner, partner, loading }) => {
	const { t } = useTranslation("challenge")

	return (
		<>
			<Typography
				mt={7}
				id='winner'
				component='h2'
				variant='h4Serif'
				textAlign='center'
				sx={{ scrollMarginTop: 40 }}
			>
				{t`winner`}
			</Typography>

			<Box
				mt={4}
				mx='auto'
				columnGap={7}
				width='100%'
				display='grid'
				maxWidth={{ xs: 320, md: "100%" }}
				justifyContent={{ xs: "stretch", md: "center" }}
				gridTemplateColumns={{ md: "repeat(2, 320px)" }}
			>
				<DesignCard loading={loading} entry={winner?.entry} />
				<Designer loading={loading} winner={winner} partner={partner} />
			</Box>
		</>
	)
}
