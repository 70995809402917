import { Box } from "@mui/material"
import type { Ref } from "react"
import { useRef } from "react"
import { InView } from "react-intersection-observer"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import {
	type Challenge,
	useAllChallengeResults,
	useMyEntry,
	useTopChallengeResults,
} from "../../../../../api/hooks/challenge"
import { Root } from "../../../../../utils"
import { Entry } from "../entry"
import { useListScroll } from "../hooks"
import { Loader } from "../loader"
import { List } from "../styles"
import { Title } from "../title"

interface Props {
	challenge: Challenge
}

export const VoteList = ({ challenge }: Props) => {
	const { slug } = useTypedParams(Root.Challenge)
	const { challengeResults: topResults } = useTopChallengeResults(
		slug,
		challenge,
		"cache-only",
	)
	const { challengeResults, loading } = useAllChallengeResults(slug)

	const totalEntries = challengeResults?.challengeEntries?.length

	const { myEntry, myEntryLoading } = useMyEntry(challenge.id)
	const itemRef = useRef<HTMLElement>()
	const listRef = useRef<HTMLDivElement>()
	const userPlace = myEntry?.entryPlace

	const { scrollToItem, getItemRef } = useListScroll({
		scrollTo: userPlace ?? 0,
		total: totalEntries ?? 0,
		listRef,
		itemRef,
	})

	return (
		<>
			<Title
				myEntry={myEntry}
				totalEntries={totalEntries}
				isManualWinner={topResults?.winner?.isWinnerMadeByAdmin}
				partner={challenge.partner?.name}
				onClick={scrollToItem}
			/>

			<div ref={listRef as Ref<HTMLDivElement>}>
				<List loading={myEntryLoading || loading} loader={<Loader />}>
					{challengeResults?.challengeEntries?.map((entry, index) => (
						<InView
							triggerOnce
							rootMargin='100px 0px'
							key={entry.id}
						>
							{({ inView, ref }) => (
								<Box ref={ref}>
									<Entry
										inView={inView}
										ref={getItemRef(index + 1)}
										active={index + 1 === userPlace}
										entry={entry}
									/>
								</Box>
							)}
						</InView>
					))}
				</List>
			</div>
		</>
	)
}
