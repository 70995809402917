import { Close, Menu as MenuIcon } from "@mui/icons-material"
import { Collapse, IconButton, Stack } from "@mui/material"
import { useToggle } from "react-use"

import { Menu } from "./menu"

export const Nav = () => {
	const [isOpen, toggle] = useToggle(false)

	return (
		<>
			<Stack width='100%' display={{ xs: "flex", md: "none" }}>
				<IconButton onClick={toggle} sx={{ alignSelf: "center" }}>
					{isOpen ? <Close /> : <MenuIcon />}
				</IconButton>
				<Collapse in={isOpen}>
					<Menu direction='column' onClick={toggle} />
				</Collapse>
			</Stack>
			<Stack display={{ xs: "none", md: "flex" }}>
				<Menu />
			</Stack>
		</>
	)
}
