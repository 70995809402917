import { Button, Divider, Stack } from "@mui/material"
import type { FC } from "react"

import { useMenu } from "../hooks"

interface Props {
	direction?: "row" | "column"
	onClick?: () => void
}
export const Menu: FC<Props> = ({ direction = "row", onClick }) => {
	const items = useMenu()

	return (
		<Stack
			mx='auto'
			direction={direction}
			divider={<Divider />}
			justifyContent='center'
			maxWidth={{ xs: 220, md: "100%" }}
		>
			{items.map(({ link, text }, index) => (
				<Button
					sx={{ textTransform: "none" }}
					key={index}
					href={link}
					onClick={onClick}
				>
					{text}
				</Button>
			))}
		</Stack>
	)
}
