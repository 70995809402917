import styled from "@emotion/styled"

export const Path = styled("path")({
	fill: "#212121",
	fillOpacity: 0.8,
	transition: "fill, fill-opacity 0.3s ease-in-out",
})

export const Container = styled.div`
	:hover {
		${Path} {
			fill: #f00;
			fill-opacity: 1;
		}
	}
`

export const Button = styled.button`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 68px;
	height: 48px;
	border: none;
	padding: 0;
	outline: none;
	cursor: pointer;
	background: transparent;
	:hover {
		${Path} {
			fill: #f00;
			fill-opacity: 1;
		}
	}
`
