import { Stack } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../../../api/hooks/home"
import { Action } from "./action"
import { Loader } from "./laoder"
import { normalizeChallenge } from "./schema"

interface Props {
	loading: boolean
	challenges: Partial<Collection<"vote" | "winner", Challenge>>
}

export const Actions: FC<Props> = ({ loading, challenges }) => {
	const { t } = useTranslation("home")

	if (loading) return <Loader />

	return (
		<Stack
			gap={5}
			width='100%'
			mb={{ sx: 5, xl: 0 }}
			sx={{ placeItems: "center" }}
			justifyContent='space-between'
		>
			{normalizeChallenge(challenges, t).map((challenge, idx) => (
				<Action key={idx} data={challenge} />
			))}
		</Stack>
	)
}
