import type { WatchQueryFetchPolicy } from "@apollo/client"
import { useQuery } from "@apollo/client"
import { TopChallengeResultsDocument } from "@artists/gql/design-challenges"

import {
	AnalyticsPageTitle,
	createViewListAnalytics,
	useAnalytics,
} from "../../../../utils"
import type { Challenge } from "./top-results.types"

export const useTopChallengeResults = (
	slug: string,
	challenge?: Challenge,
	fetchPolicy?: WatchQueryFetchPolicy,
) => {
	const { send } = useAnalytics()
	const { data, loading } = useQuery(TopChallengeResultsDocument, {
		variables: { slug },
		ssr: false,
		skip: !challenge,
		fetchPolicy,
		onCompleted: data => {
			if (data.challengeResults?.topChallengeEntries) {
				if (!challenge) return

				send(
					createViewListAnalytics({
						challenge,
						entries: data.challengeResults?.topChallengeEntries,
						page: {
							title: AnalyticsPageTitle.ResultsPublic,
						},
					}),
				)
			}
		},
	})

	return {
		loading,
		challengeResults: data?.challengeResults,
		isDataReceived: !!data,
	}
}
