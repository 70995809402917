import type { TFunction } from "i18next"
import type { ReactElement } from "react"

import {
	type Challenge,
	ChallengeStatus,
} from "../../../../../../api/hooks/home"
import { getImage, getTimes, Root } from "../../../../../../utils"
import { Time } from "../../../../../@shared"

type Key = "vote" | "winner"
type Props = Partial<Collection<Key, Challenge>>

export type NormalizedChallenge = Nullable<{
	slug: string
	image: {
		src: string
		lowSrc?: string
		sources?: ReactElement[]
	}
	title: string
	subtitle?: string
	state?: Collection<string, unknown>
	disabled: boolean
	description: ReactElement | string
	link: string
}>

export const normalizeChallenge = (
	challenges: Props,
	t: TFunction<"home">,
): NormalizedChallenge[] =>
	Object.entries(challenges).map(([key, challenge]) => {
		if (!challenge) return null

		return {
			slug: challenge.slug,
			disabled: getDisabled(challenge, key as Key),
			title: getTitle(challenge, key as Key, t).title,
			subtitle: getTitle(challenge, key as Key, t).subtitle,
			description: getDescription(
				challenge,
				t("actions.at"),
				`${t("actions.for")} ${challenge.name}`,
			),
			image: getImage(challenge.images.thumbnail320x320),
			...getLink(challenge),
		}
	})

const getDisabled = (challenge: Challenge, type: Key) =>
	challenge.status.current.name !== ChallengeStatus.VotingOpen &&
	type === "vote"

const getTitle = (challenge: Challenge, type: Key, t: TFunction<"home">) => {
	type Title = `actions.${typeof type}.title`
	const defaultTitle = {
		title: t(`actions.${type}.title` as Title),
		subtitle: undefined,
	}

	if (type === "winner") return defaultTitle

	if (challenge.status.current.name === ChallengeStatus.VotingOpen) {
		return defaultTitle
	}

	const times = getTimes({
		open: challenge.votingOpensAt,
		closed: challenge.votingClosesAt,
	})
	const isOpenNext = challenge.status.next.name === ChallengeStatus.VotingOpen
	const date = times[isOpenNext ? "open" : "closed"]

	return {
		title: isOpenNext ? t("actions.vote.open") : t("actions.vote.closed"),
		subtitle: date.dayOfMonth,
	}
}

function getLink(challenge: Challenge) {
	const isVotingOpen =
		challenge.status.current.name === ChallengeStatus.VotingOpen
	if (isVotingOpen) {
		return { link: Root.Vote.buildPath({ slug: challenge.slug }) }
	}

	return {
		link: Root.Challenge.buildPath({ slug: challenge.slug }),
		state: Root.Challenge.buildState({
			status: challenge.status.current.name,
		}),
	}
}

export const getDescription = (
	challenge: Challenge,
	at: string,
	description: string,
): ReactElement | string => {
	const isGoingToOpen =
		challenge.status.current.name === ChallengeStatus.EntriesClosed

	if (!isGoingToOpen) return description

	const times = getTimes({
		open: challenge.votingOpensAt,
	})

	return (
		<>
			{at} <Time>{times.open.timeOfDay}</Time> {description}
		</>
	)
}
