import { Box } from "@mui/material"
import type { FC } from "react"

import { BgImage } from "./bg-image"
import type { HeroProps } from "./hero.types"
import { Overlay } from "./overlay"

export const Hero: FC<HeroProps> = ({
	images,
	overlay,
	minImageHeights,
	overlaySx,
	className,
}) => (
	<Box position='relative' mx={{ xs: -3, md: -5 }} className={className}>
		<BgImage images={images} minImageHeights={minImageHeights} />
		<Overlay overlay={overlay} overlaySx={overlaySx} />
	</Box>
)
