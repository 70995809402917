import { Button, Stack, Typography } from "@mui/material"
import { sanitize } from "isomorphic-dompurify"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../api/hooks/challenge"
import { richTextSx } from "../../../@shared/rich-text"
import { Img } from "./styles"

interface Props {
	challenge?: Challenge
}

export const Partner: FC<Props> = ({ challenge }) => {
	const { t } = useTranslation("challenge")
	const partner = challenge?.partner

	if (!partner) return null

	const { name, webSite, description, buttonText } = partner
	const hasLogo = partner.__typename === "ChallengePartnerWithLogo"
	const image = hasLogo ? partner.logo?.url : partner.photo?.webP

	return (
		<Stack gap={5} pt={4} m='auto' maxWidth={800} alignItems='center'>
			<Typography
				variant='h4'
				textAlign='center'
				textTransform='capitalize'
			>
				{t("partner.ourPartner", { name })}
			</Typography>

			{image && <Img isLogo={hasLogo} src={image} />}

			<Typography
				component='div'
				sx={richTextSx}
				dangerouslySetInnerHTML={{
					__html: sanitize(description ?? ""),
				}}
			/>
			<Button
				variant='outlined'
				target='_blank'
				href={webSite}
				sx={{ mb: 5 }}
			>
				{buttonText}
			</Button>
		</Stack>
	)
}
