import { Image } from "@artists/uikit"
import type { FC } from "react"

interface Props {
	videoID: string
}
export const Preview: FC<Props> = ({ videoID }) => (
	<Image
		alt='video preview'
		src={`https://i.ytimg.com/vi/${videoID}/maxresdefault.jpg`}
		sources={[
			{
				type: "image/webp",
				srcSet: `https://i.ytimg.com/vi_webp/${videoID}/maxresdefault.webp`,
			},
		]}
		style={{ height: "auto", width: "100%", paddingTop: "56.25%" }}
	/>
)
