import { Image } from "@artists/uikit"
import { Box, Stack } from "@mui/material"

export const Images = () => (
	<Stack
		gap={2}
		flexDirection={{ xs: "row", lg: "column" }}
		width={{ xs: "100%", lg: "350px" }}
		height={{ xs: 196, md: 300, lg: "auto" }}
		paddingX={{ xs: 0, md: 3, lg: 0 }}
	>
		<Box
			sx={{
				flexBasis: { xs: `${100 / 3}%`, lg: "60%" },
				"& img": { objectPosition: "top" },
			}}
		>
			<Image src='https://cdn.spoonflower.com/artist/KY9A9796.jpg' />
		</Box>

		<Box
			sx={{
				flexBasis: { xs: `${(100 * 2) / 3}%`, lg: "40%" },
				"& img": { objectPosition: "top" },
			}}
		>
			<Image src='https://cdn.spoonflower.com/artist/KY9A9581.jpg' />
		</Box>
	</Stack>
)
