import { useQuery } from "@apollo/client"
import { AllChallengeResultsDocument } from "@artists/gql/design-challenges"

export const useAllChallengeResults = (slug: string) => {
	const { data, loading } = useQuery(AllChallengeResultsDocument, {
		variables: { slug },
		ssr: false,
	})

	return {
		loading,
		challengeResults: data?.challengeResults,
		isDataReceived: !!data,
	}
}
