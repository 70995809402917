import { Box } from "@mui/material"
import type { FC } from "react"

import type { Winner } from "../../../../../../api/hooks/challenge"
import { Description } from "./description"
import { Hero } from "./hero"
import { Info } from "./info"
import { Loader } from "./loader"

interface Props {
	loading?: boolean
	winner: Maybe<Winner>
	partner: string
}
export const Designer: FC<Props> = ({ winner, partner, loading }) => {
	if (loading) return <Loader />
	if (!winner) return null

	return (
		<Box mt={6}>
			<Hero winner={winner} />
			<Description winner={winner} />
			<Info isManual={winner.isWinnerMadeByAdmin} partner={partner} />
		</Box>
	)
}
