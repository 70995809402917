import type { SxProps } from "@mui/system"

export const container: SxProps = {
	py: 4,
	px: 2,
	height: "100%",
	bgcolor: "paper.secondary",
	overflow: "hidden",
	borderRadius: "4px",
	position: "relative",
	textAlign: "center",
}
