import { ProductName } from "@artists/gql/design-challenges"

export const displayProductName = (productName: ProductName) => {
	const productConfig: Collection<ProductName, string> = {
		[ProductName.TeaTowel]: "Tea Towel",
		[ProductName.SheetSet]: "Sheet Set",
		[ProductName.SheetSetTopView]: "Sheet Set",
		[ProductName.Tablecloth]: "Tablecloth",
		[ProductName.SquareThrowPillow]: "Square Throw Pillow",
		[ProductName.Wallpaper]: "Wallpaper",
		[ProductName.WallpaperSilverMetallic]: "Wallpaper",
		[ProductName.WallpaperGoldMetallic]: "Wallpaper",
		[ProductName.Duvet]: "Duvet",
		[ProductName.DuvetTopView]: "Duvet",
		[ProductName.Curtains]: "Curtains",
		[ProductName.TableRunner]: "Table Runner",
		[ProductName.WallHanging]: "Wall Hanging",
		[ProductName.FabricYard]: "Yard of Fabric",
		[ProductName.FabricSwatch]: "Swatch of Fabric",
		[ProductName.FabricFatQuarter]: "Fat Quarter of Fabric",
		[ProductName.FabricCrop]: "Fabric",
	}

	return productConfig[productName]
}
