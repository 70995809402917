import { ChallengeStatusName } from "@artists/gql/design-challenges"
import { Stack } from "@mui/material"
import { useToggle } from "react-use"

import type { Challenge } from "../../../../../../api/hooks/challenge"
import { More } from "./more"
import { Summary } from "./summary"

interface Props {
	challenge?: Challenge
}

export const Details = ({ challenge }: Props) => {
	const [open, toggle] = useToggle(false)
	if (!challenge) return null

	const fullView =
		challenge.status.current.name === ChallengeStatusName.ResultsPublic

	return (
		<Stack spacing={2} alignItems='center'>
			<More
				isOpen={open || !!fullView}
				product={challenge.product.name}
				acceptableRepeats={challenge.acceptableRepeats}
				challengeStatus={challenge?.status.current.name}
				entriesClose={challenge.entriesCloseAt}
				topCount={challenge.topDesignsCount}
				termsLink={challenge.rules}
			/>
			{!fullView && <Summary toggle={toggle} isOpen={open} />}
		</Stack>
	)
}
