import { Box, Skeleton } from "@mui/material"
import { type FC, useState } from "react"

import { PlayButton } from "./play-btn"
import { Preview } from "./preview"
import { Container } from "./styled"

interface Props {
	loading?: boolean
	videoID: string
}
export const YouTube: FC<Props> = ({ loading, videoID }) => {
	const [showFrame, setShowFrame] = useState(false)

	return (
		<Box
			bgcolor='#D8D8D8'
			position='relative'
			onClick={() => setShowFrame(true)}
			style={{
				cursor: "pointer",
				aspectRatio: "16 / 9",
				borderRadius: 4,
			}}
		>
			{showFrame ? (
				<iframe
					title='youtube'
					width='100%'
					height='100%'
					style={{
						top: 0,
						left: 0,
						border: "none",
						position: "absolute",
					}}
					src={`https://www.youtube.com/embed/${videoID}?autoplay=1&rel=0`}
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				/>
			) : (
				<Container>
					{loading ? (
						<Skeleton
							sx={{
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								transform: "none",
								position: "absolute",
							}}
						/>
					) : (
						<Preview videoID={videoID} />
					)}
					<PlayButton />
				</Container>
			)}
		</Box>
	)
}
