import { getAuthUrl, getDefined, joinPath } from "@artists/devkit"
import { ChallengeStatusName } from "@artists/gql/design-challenges"
import type { TFunction } from "i18next"
import { Link } from "react-router-dom"

import { getENV } from "../../../../../../@common"
import type { Challenge } from "../../../../../api/hooks/challenge"
import { format, getStatus, Root } from "../../../../../utils"
import { Time } from "../../../../@shared"
import type { Config } from "./overlay.types"

export const getConfig = (
	t: TFunction<"challenge" | "common">,
	commonT: TFunction<"common">,
	{ slug, resultsPublicAt, status }: Challenge,
	authorized: boolean,
): Config => {
	const { SPOONFLOWER_HOST, HOST } = getENV()
	const statusTime = getStatus(commonT, status.next)

	return {
		[ChallengeStatusName.Deactivated]: {
			button: { disabled: true, children: t`hero.enterDesign` },
			subtitle: { ...statusTime, color: "warning.dark" },
		},
		[ChallengeStatusName.Scheduled]: {
			button: { disabled: true, children: t`hero.enterDesign` },
			subtitle: { ...statusTime, color: "warning.dark" },
		},
		[ChallengeStatusName.EntriesOpen]: {
			button: {
				target: "_blank",
				children: t`hero.enterDesign`,
				href: authorized
					? joinPath(SPOONFLOWER_HOST, "designs")
					: getAuthUrl({
							host: SPOONFLOWER_HOST,
							fallbackUrl: joinPath(HOST, slug),
						}),
			},
			subtitle: { ...statusTime, color: "warning.dark" },
		},
		[ChallengeStatusName.EntriesClosed]: {
			button: {
				disabled: true,
				children: (
					<>
						{t`hero.closed`}&nbsp;
						<Time>
							{format(
								getDefined(status.current.date),
								"MMM d 'at' ha z",
							)}
						</Time>
					</>
				),
			},
			subtitle: { text: t`hero.entriesClosed`, color: "warning.dark" },
		},
		[ChallengeStatusName.VotingOpen]: {
			button: {
				component: Link,
				children: t`hero.vote`,
				to: Root.Vote.buildPath({ slug }),
			},
			subtitle: { ...statusTime, color: "warning.dark" },
		},
		[ChallengeStatusName.VotingClosed]: {
			button: { disabled: true, children: t`hero.votingClosed` },
			subtitle: {
				...getStatus(commonT, {
					name: ChallengeStatusName.ResultsPublic,
					date: resultsPublicAt,
				}),
				color: "warning.dark",
			},
		},
		[ChallengeStatusName.ResultsFinal]: {
			button: { disabled: true, children: t`hero.votingClosed` },
			subtitle: { ...statusTime, color: "warning.dark" },
		},
		[ChallengeStatusName.ResultsPublic]: {
			button: {
				children: t`hero.shop`,
				href: "#winner",
			},
			subtitle: {
				label: t`ended`,
				time: format(new Date(resultsPublicAt), "MMMM d, yyyy"),
				color: "text.secondary",
			},
		},
	}
}
