import { joinPath } from "@artists/devkit"
import {
	Card,
	CardContent,
	CardMedia,
	CardSubTitle,
	CardTitle,
} from "@artists/uikit"
import { Button, Divider, Link } from "@mui/material"
import type { FC } from "react"
import { Trans, useTranslation } from "react-i18next"

import { getENV } from "../../../../../../../@common"
import type { Entry } from "../../../../../../api/hooks/challenge"
import { designerLink } from "../../../../../../utils"
import { ActionCard, Favorite, useActionStatuses } from "../../../../../@shared"
import { Loader } from "./loader"

interface Props {
	loading?: boolean
	entry?: Entry
}
export const DesignCard: FC<Props> = ({ entry, loading }) => {
	const action = useActionStatuses(entry)

	const {
		t,
		i18n: { resolvedLanguage = "en" },
	} = useTranslation("challenge")

	if (loading) return <Loader />

	if (action) return <ActionCard {...action} />

	if (!entry) return null

	return (
		<Card>
			<Link href={entry.pdpUrl} target='_blank'>
				<CardMedia label='1st' image={entry.entryImage} />
			</Link>

			<CardContent>
				<CardTitle>
					<Link href={entry.pdpUrl} target='_blank' underline='hover'>
						{entry.entryTitle}
					</Link>
				</CardTitle>

				<CardSubTitle>
					<Trans
						ns='challenge'
						i18nKey='byDesigner'
						values={{ designer: entry.user.userName }}
					>
						<Link
							target='_blank'
							underline='hover'
							color='inherit'
							href={designerLink(entry.user.userName)}
						/>
					</Trans>
				</CardSubTitle>
			</CardContent>

			<Favorite entry={entry} sx={{ m: 1, mt: -1 }} />

			<Divider />

			<Button
				target='_blank'
				sx={{ width: "100%", py: 1, borderRadius: 0 }}
				href={joinPath(
					getENV("SPOONFLOWER_HOST"),
					resolvedLanguage,
					"products",
					entry.designId,
				)}
			>
				{t`seeOnProducts`}
			</Button>
		</Card>
	)
}
