import { useQuery } from "@apollo/client"
import { ChallengesDocument } from "@artists/gql/design-challenges"

import type { ChallengeResponse } from "./challenges.types"

export const useChallenges = (): ChallengeResponse => {
	const { loading, data } = useQuery(ChallengesDocument)

	return {
		loading,
		current: data?.current.results ?? [],
		past: data?.past.results ?? [],
		prizes: data?.defaultPrizes || [],
		partners: data?.partners ?? { regular: [], premium: [] },
	}
}
