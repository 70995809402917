import { ClipBorder } from "@artists/uikit"
import { Button, type ButtonProps } from "@mui/material"
import type { FC } from "react"
import { Link } from "react-router-dom"

interface Props extends ButtonProps {
	to: string
	state?: Collection<string, unknown>
}

export const Card: FC<Props> = ({ to, disabled, state, children }) => (
	<Button
		to={to}
		disableRipple
		component={Link}
		disabled={disabled}
		state={state}
		sx={({ breakpoints }) => ({
			p: 0,
			width: "100%",
			overflow: "hidden",
			borderRadius: "5px",
			textTransform: "none",
			boxShadow: "2px 2px 8px 0 rgba(51,51,51,0.15)",
			[breakpoints.down(430)]: { maxWidth: 320 },
			"&:hover, &:focus": {
				backgroundColor: "action.hover",
				"& .card-image": { transform: "scale(1.05)" },
			},
		})}
	>
		<ClipBorder
			sx={({ breakpoints }) => ({
				gap: 3,
				width: "100%",
				display: "grid",
				alignItems: "center",
				gridTemplateColumns: {
					xs: "140px 1fr 42px",
					md: "160px 1fr 46px",
					lg: "190px 1fr 48px",
				},
				[breakpoints.down(530)]: { "&": { gap: 1 } },
				[breakpoints.down(430)]: {
					"&": { gridTemplateColumns: "1fr", gap: 2, pb: 2 },
				},
			})}
		>
			{children}
		</ClipBorder>
	</Button>
)
