import type { ListProps } from "@artists/uikit"
import { List as AppList } from "@artists/uikit"
import { Box, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Card = styled(Box, {
	shouldForwardProp: prop => prop !== "active",
})<{ active?: boolean }>(({ active, theme }) =>
	theme.unstable_sx({
		gap: { xs: 1.5, sm: 2 },
		padding: "12px 40px 12px 16px",
		display: "grid",
		alignItems: "center",
		gridTemplateColumns: "60px 55px 1fr",
		...(active && {
			background: "#f5f5f5",
			margin: "-1px 0",
			zIndex: 1,
		}),
	}),
)

export const List = styled((props: ListProps) => (
	<AppList divider={<Divider sx={{ ml: 9, mr: 5 }} />} {...props} />
))(({ theme }) => ({
	margin: "0 auto",
	border: "1px solid",
	height: 500,
	maxWidth: 900,
	overflow: "auto",
	borderRadius: 4,
	borderColor: theme.palette.divider,
}))
