import { homeChallengesIds } from "@artists/challenges-testkit"
import { StarRate } from "@mui/icons-material"
import { ListItem, Stack, Typography } from "@mui/material"
import { sanitize } from "isomorphic-dompurify"

import { richTextSx } from "../rich-text"

interface Props {
	prizeText: string
}

export const PrizeBullet = ({ prizeText }: Props) => (
	<ListItem>
		<Typography
			component='div'
			variant='body2'
			display='flex'
			alignItems='flex-start'
			sx={richTextSx}
		>
			<Stack direction='row' alignItems='center' marginRight={1}>
				&#8203;
				<StarRate
					sx={{
						color: "#ECB201",
						fontSize: "inherit",
						verticalAlign: "center",
					}}
				/>
			</Stack>
			<div
				data-testid={homeChallengesIds.prizeText}
				dangerouslySetInnerHTML={{ __html: sanitize(prizeText) || "" }}
			></div>
		</Typography>
	</ListItem>
)
