import { useQuery } from "@apollo/client"
import { ChallengeDocument } from "@artists/gql/design-challenges"

import type { UseChallengeResponse } from "./challenge.types"

export const useChallengeBySlug = (slug = ""): UseChallengeResponse => {
	const { loading, data } = useQuery(ChallengeDocument, {
		variables: { slug },
		skip: !slug,
	})

	return {
		loading,
		isDataReceived: !!data,
		challenge: data?.challenge,
	}
}
