import { Box, Divider, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Partners as TPartners } from "../../../../api/hooks/home"
import { Logos } from "./logos"
import { Tags } from "./tags"

interface Props {
	loading?: boolean
	partners: TPartners
}

export const Partners: FC<Props> = ({ loading, partners }) => {
	const { t } = useTranslation("home")

	return (
		<Box component='section'>
			<Divider sx={{ my: { md: 9, xs: 8 } }} />
			<Stack id='partners' gap={{ md: 7, xs: 6 }}>
				<Typography textAlign='center' variant='h4Serif' component='h2'>
					{t`partners.title`}
				</Typography>
				<Logos loading={loading} partners={partners.premium} />
				<Tags loading={loading} partners={partners.regular} />
			</Stack>
		</Box>
	)
}
