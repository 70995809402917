import type { ButtonProps } from "@mui/material"
import { Button } from "@mui/material"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"
import type { ReactNode } from "react"
import { Link } from "react-router-dom"

interface Props {
	to: string
	state?: Collection<string, unknown>
	children: ReactNode
}

export const Tag = styled((props: Props) => (
	<Button disableRipple component={Link} {...props} />
))<ButtonProps>(({ theme: { palette } }) => ({
	textTransform: "none",
	backgroundColor: grey[200],
	color: palette.getContrastText(grey[200]),
	"&:hover, &:focus": { backgroundColor: grey[300] },
}))
