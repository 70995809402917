import { Divider, Skeleton, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

import { Card } from "../styles"

export const Loader = () => {
	const { t } = useTranslation("challenge")

	return (
		<>
			{[...Array(10).keys()].map(idx => (
				<Fragment key={idx}>
					<Card>
						<Typography
							variant='h5'
							color='text.secondary'
							textAlign='center'
						>
							{idx + 1}
						</Typography>
						<Stack minWidth={50} alignItems='center'>
							<Skeleton
								sx={{
									width: 46,
									height: 32,
									borderRadius: 20,
									transform: "none",
								}}
							/>
							<Typography variant='body2' color='text.secondary'>
								{t`votes`}
							</Typography>
						</Stack>
						<Stack>
							<Typography variant='h5'>
								<Skeleton />
							</Typography>
							<Typography width='30%'>
								<Skeleton />
							</Typography>
						</Stack>
					</Card>
					{idx !== 9 && <Divider sx={{ ml: 10, mr: 5 }} />}
				</Fragment>
			))}
		</>
	)
}
