import { Image } from "@artists/uikit"
import { Box, Button, Skeleton } from "@mui/material"
import type { FC } from "react"
import { Link } from "react-router-dom"

import type { Partners } from "../../../../../api/hooks/home"
import { Root } from "../../../../../utils"

interface Props {
	loading?: boolean
	partners: Partners["premium"]
}

export const Logos: FC<Props> = ({ loading, partners }) => (
	<Box
		rowGap={1}
		columnGap={6}
		display='flex'
		flexWrap='wrap'
		alignItems='center'
		sx={{ placeContent: "center", "&:empty": { display: "none" } }}
	>
		{loading
			? [...Array(3).keys()].map(i => (
					<Skeleton
						key={i}
						height={57}
						width={200}
						sx={{ transform: "none" }}
						animation='wave'
					/>
				))
			: partners.map(p => (
					<Button
						key={p.id}
						disableRipple
						component={Link}
						to={Root.Challenge.buildPath({ slug: p.challengeSlug })}
						// state={Root.Challenge.buildState({
						// 	status: ChallengeStatusName.ResultsPublic
						// })}
						sx={({ transitions }) => ({
							p: 0,
							width: 200,
							height: 57,
							overflow: "hidden",
							transform: "scale(1)",
							filter: "brightness(1)",
							transition: transitions.create(
								["filter", "transform", "box-shadow"],
								{
									duration: transitions.duration.complex,
								},
							),
							"&:hover, &:focus": {
								transform: "scale(1.02)",
								boxShadow: "0 1px 6px 0 rgb(0 0 0 / 3%)",
								filter: "brightness(0.95)",
							},
						})}
					>
						{p.priorityLogo && (
							<Image
								src={p.priorityLogo.url}
								style={{ objectFit: "contain" }}
								alt={p.name}
							/>
						)}
					</Button>
				))}
	</Box>
)
