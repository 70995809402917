import type { FC } from "react"

import type { Challenge } from "../../../../api/hooks/home"
import { Description } from "./description"
import { Info } from "./info"
import { Sticky } from "./sticky"

interface Props {
	loading: boolean
	challenges: Partial<Collection<"vote" | "winner", Challenge>>
}
export const Header: FC<Props> = props => (
	<>
		<Description />
		<Sticky />
		<Info {...props} />
	</>
)
