import { homeChallengesIds } from "@artists/challenges-testkit"
import { Button, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import type { PastChallenges } from "../../../../api/hooks/home"
import { Root } from "../../../../utils"
import { ChallengesList } from "../../../@shared/challenges"
import { scrollMarginTop } from "../../style"
import { normalizeChallenges } from "./shop.schema"

interface Props {
	loading: boolean
	challenges: PastChallenges
}

export const Shop: FC<Props> = ({ loading, challenges }) => {
	const { t } = useTranslation("home")

	return (
		<Stack
			id='shop'
			gap={{ xs: 5, md: 6 }}
			sx={{ scrollMarginTop }}
			data-testid={homeChallengesIds.pastChallenges}
		>
			<Typography textAlign='center' variant='h4Serif' component='h2'>
				{t`shop.title`}
			</Typography>

			<ChallengesList
				count={4}
				loading={loading}
				items={normalizeChallenges(challenges)}
			/>

			<Button
				component={Link}
				variant='outlined'
				to={Root.Past.path}
				sx={{ alignSelf: "center", py: 1, px: 2 }}
				data-testid={homeChallengesIds.shopPastChallenges}
			>
				{t`shop.btn`}
			</Button>
		</Stack>
	)
}
