import { Button, Divider, Skeleton, Stack, Typography } from "@mui/material"
import { type FC, Fragment } from "react"

import type { TimeData } from "../../../../../utils"
import { Time as SharedTime } from "../../../../@shared"
import * as css from "./styles"

interface Props {
	loading: boolean
	title: string
	active: boolean
	isPast?: boolean
	data: TimeData[]
}
export const Time: FC<Props> = ({ loading, title, data, isPast, active }) => (
	<Button
		disableRipple
		disabled={isPast}
		sx={{
			p: 0,
			display: "block",
			cursor: "default",
			textTransform: "none",
			border: active ? 2 : 0,
		}}
	>
		<Stack sx={css.container}>
			<Typography
				component='h3'
				fontWeight='bold'
				sx={{ textDecoration: isPast ? "line-through" : "none" }}
			>
				{title}
			</Typography>

			<Divider sx={{ my: 4 }} />

			<Typography variant='h4'>
				{loading ? (
					<Skeleton sx={{ width: "80%", mx: "auto" }} />
				) : (
					<>
						{data.map((t, idx) => (
							<Fragment key={idx}>
								<SharedTime>{`${t.dayOfMonth}`}</SharedTime>
								{idx !== data.length - 1 && " – "}
							</Fragment>
						))}
					</>
				)}
			</Typography>

			<Typography
				mt={4}
				sx={{ whiteSpace: "pre-line" }}
				color={isPast ? "text.disabled" : "text.primary"}
			>
				{loading ? (
					<Skeleton sx={{ width: "70%", mx: "auto" }} />
				) : (
					<>
						{data.map((d, idx) => (
							<Fragment key={idx}>
								<SharedTime key={idx}>
									{d.dayAndTimeOfMonth}
								</SharedTime>
								{idx !== data.length - 1 && (
									<span style={{ whiteSpace: "pre" }}>
										{"– \n"}
									</span>
								)}
							</Fragment>
						))}
					</>
				)}
			</Typography>
		</Stack>
	</Button>
)
