import { Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

import { Root } from "../../../../utils"

export const Footer = () => {
	const { t } = useTranslation("challenge")

	return (
		<Stack
			mt={5}
			gap={2}
			mx='auto'
			width='100%'
			maxWidth={800}
			justifyContent='center'
			flexDirection={{ sm: "row", xs: "column" }}
		>
			<Button
				component={RouterLink}
				variant='outlined'
				to={Root.path}
				color='primary'
			>
				<Typography textTransform='capitalize'>
					{t("footer.currentChallenges")}
				</Typography>
			</Button>
			<Button
				component={RouterLink}
				variant='outlined'
				to={Root.Past.path}
			>
				<Typography textTransform='capitalize'>
					{t("footer.pastChallenges")}
				</Typography>
			</Button>
		</Stack>
	)
}
