import { Card, CardContent, CardSubTitle, CardTitle } from "@artists/uikit"
import { Button, Divider, Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Favorite } from "../../../../../@shared"

export const Loader = () => {
	const [t] = useTranslation("challenge")

	return (
		<Card>
			<Skeleton
				animation='wave'
				sx={{
					pt: "100%",
					borderRadius: "0",
					transform: "none",
					"&:empty:before": { content: "''" },
				}}
			/>

			<CardContent>
				<CardTitle width='90%'>
					<Skeleton />
				</CardTitle>
				<CardSubTitle width='40%'>
					<Skeleton />
				</CardSubTitle>
			</CardContent>

			<Favorite disabled sx={{ m: 1, mt: -1 }} />

			<Divider />

			<Button fullWidth disabled>{t`seeOnProducts`}</Button>
		</Card>
	)
}
