import { Link, Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { Trans } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

import type { MyEntry } from "../../../../../api/hooks/challenge"

interface Props {
	totalEntries: Maybe<number>
	myEntry: Maybe<MyEntry>
	isManualWinner: Maybe<boolean>
	partner?: string
	onClick?: () => void
}
export const Title: FC<Props> = ({
	totalEntries,
	partner,
	isManualWinner,
	myEntry,
	onClick,
}) => (
	<Stack mt={5} mb={3}>
		<Typography variant='h4Serif' component='h2' textAlign='center'>
			<Trans
				ns='challenge'
				i18nKey={myEntry ? "votesTitle" : "listTitle"}
				values={{
					...(myEntry && {
						place: myEntry.entryPlace,
						total: totalEntries,
						votes: myEntry.validVotes,
					}),
				}}
			>
				<Link sx={{ cursor: "pointer" }} onClick={onClick} />
			</Trans>
		</Typography>

		{isManualWinner && (
			<Typography textAlign='center'>
				<Trans
					ns='challenge'
					i18nKey='manualWinner'
					values={{ partner: partner ?? "Spoonflower" }}
				>
					<Link component={RouterLink} to='#showDetails' />
				</Trans>
			</Typography>
		)}
	</Stack>
)
