import type { Challenge } from "../../../../api/hooks/home"
import { format, getImage } from "../../../../utils"
import type { ListItem } from "../../../@shared/challenges"

export const normalizeChallenges = (challenges: Challenge[]): ListItem[] =>
	challenges.map(c => ({
		id: c.id,
		slug: c.slug,
		title: c.name,
		status: c.status.current.name,
		subtitle: {
			label: "Ended",
			time: format(c.entriesCloseAt, "MMM d, yyyy"),
		},
		image: getImage(c.images.thumbnail320x320),
	}))
