import { type FC, useEffect } from "react"
import { useTypedState } from "react-router-typesafe-routes/dom"

import type { Challenge as IChallenge } from "../../api/hooks/challenge"
import { isPublic, Root, useAnalytics } from "../../utils"
import { ChallengeBreadcrumbs } from "../@shared"
import {
	Details,
	Footer,
	FullVotes,
	Hero,
	Partner,
	Prizes,
	Results,
	Timeline,
} from "./components"

interface Props {
	loading: boolean
	challenge: Optional<IChallenge>
}
export const Challenge: FC<Props> = ({ loading, challenge }) => {
	const { send } = useAnalytics()
	const { status } = useTypedState(Root.Challenge)
	const isPast = isPublic(challenge?.status.current.name || status)

	useEffect(() => {
		if (!challenge) return

		send({
			event: "challengeView",
			pageState: challenge.status.current.name,
		})
	}, [challenge, send])

	return (
		<>
			<ChallengeBreadcrumbs sx={{ my: 3, mx: "auto" }} />
			<Hero loading={loading} challenge={challenge} />
			{isPast && <Results loading={loading} challenge={challenge} />}
			<Details loading={loading} challenge={challenge} />
			{isPast && challenge && <FullVotes challenge={challenge} />}
			{!isPast && <Timeline loading={loading} challenge={challenge} />}
			{!isPast && <Prizes loading={loading} challenge={challenge} />}
			<Partner challenge={challenge} />
			<Footer />
		</>
	)
}
