import { Box } from "@mui/material"

import type { Challenge } from "../../../../api/hooks/challenge"
import { Prizes as SharedPrizes } from "../../../@shared/prizes"

interface Props {
	loading: boolean
	challenge?: Challenge
}

export const Prizes = ({ loading, challenge }: Props) => (
	<Box width='100%' mx='auto' maxWidth={792} mt={7}>
		<SharedPrizes
			loading={loading}
			prizes={challenge?.prizes ?? []}
			id='prizes'
		/>
	</Box>
)
