import { ChallengeStatusName } from "@artists/gql/design-challenges"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../api/hooks/challenge"
import { Container } from "../container"
import { Collapse } from "../index"
import { Description } from "./description"
import { Info } from "./info"
import { Player } from "./player"

interface Props {
	loading?: boolean
	challenge?: Challenge
}

export const Details: FC<Props> = ({ loading, challenge }) => {
	const { t } = useTranslation("challenge")

	const content = (
		<Container divider>
			<Description loading={loading} intro={challenge?.intro} />
			<Info loading={loading} challenge={challenge} />
			{challenge?.status.current.name ===
				ChallengeStatusName.EntriesOpen &&
				challenge?.youtubeVideoId && (
					<Player
						loading={loading}
						videoID={challenge.youtubeVideoId}
					/>
				)}
		</Container>
	)

	if (challenge?.status.current.name === ChallengeStatusName.ResultsPublic) {
		return <Collapse label={t`challengeDetails`}>{content}</Collapse>
	}

	return content
}
