import { useTranslation } from "react-i18next"

import { useAuth } from "../../../../../api"
import type { Challenge } from "../../../../../api/hooks/challenge"
import { getConfig } from "./overlay.utils"

export const useOverlay = (challenge?: Challenge) => {
	const [t] = useTranslation("challenge")
	const [commonT] = useTranslation("common")
	const { authorized } = useAuth()

	if (!challenge) {
		return {
			title: "",
			button: {},
			challengeName: "",
			subtitle: { color: "", text: "" },
		}
	}

	const config = getConfig(t, commonT, challenge, authorized)
	const partner = challenge.partner?.name

	return {
		...config[challenge.status.current.name],
		title: t("hero.sfPresents", { partner, count: partner ? 2 : 0 }),
		challengeName: t("hero.challengeName", {
			challengeName: challenge.name,
		}),
	}
}
