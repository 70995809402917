import { KeyboardArrowRight } from "@mui/icons-material"
import { Skeleton, Stack, Typography } from "@mui/material"

import { Card } from "../action/card"

export const Loader = () => (
	<Stack gap={4} width='100%' sx={{ placeItems: "center" }}>
		{[...Array(2).keys()].map(idx => (
			<Card key={idx} disabled to='#'>
				<Skeleton
					width='100%'
					height='100%'
					animation='wave'
					sx={{
						transform: "none",
						aspectRatio: "1",
						borderRadius: 0,
					}}
				/>

				<Stack overflow='hidden' px={1}>
					<Typography variant='h4' width={{ xs: "100%", md: "80%" }}>
						<Skeleton />
					</Typography>
					<Typography width={{ xs: "40%", md: "35%" }}>
						<Skeleton />
					</Typography>
				</Stack>

				<KeyboardArrowRight
					sx={({ breakpoints }) => ({
						[breakpoints.down(430)]: { display: "none" },
					})}
					fontSize='large'
				/>
			</Card>
		))}
	</Stack>
)
