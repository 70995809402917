import { ChallengeStatusName } from "@artists/gql/design-challenges"

import type { CurrentChallenges } from "../../api/hooks/home"

export function getVoteChallenge(
	challenges: CurrentChallenges,
): CurrentChallenges[number] | undefined {
	const challengeWithCurrentOpenVoting = challenges.find(
		({ status }) => status.current.name === ChallengeStatusName.VotingOpen,
	)

	const challengeWithNextOpenVoting = challenges.find(
		({ status }) => status.next.name === ChallengeStatusName.VotingOpen,
	)

	return challengeWithCurrentOpenVoting || challengeWithNextOpenVoting
}
