import { Card, CardContent, CardSubTitle, CardTitle } from "@artists/uikit"
import { Skeleton } from "@mui/material"
import type { FC } from "react"

import { Favorite } from "../../../../../@shared"

interface Props {
	len?: number
}
export const Loader: FC<Props> = ({ len = 8 }) => (
	<>
		{[...Array(len).keys()].map(i => (
			<Card key={i}>
				<Skeleton
					animation='wave'
					sx={{
						pt: "100%",
						borderRadius: "0",
						transform: "none",
						"&:empty:before": { content: "''" },
					}}
				/>

				<CardContent>
					<CardTitle width='90%'>
						<Skeleton />
					</CardTitle>
					<CardSubTitle width='40%'>
						<Skeleton />
					</CardSubTitle>
				</CardContent>

				<Favorite disabled sx={{ m: 1, mt: -1 }} />
			</Card>
		))}
	</>
)
