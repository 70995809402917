function isDefined<T>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined
}

function assertIsDefined<T>(
	value: T | null | undefined,
	message?: string,
): asserts value is T {
	if (!isDefined(value)) {
		throw new Error(message || "Expected value to be defined.")
	}
}

function getDefined<T>(value: T | null | undefined, message?: string): T {
	assertIsDefined(value, message)

	return value
}

export { assertIsDefined, getDefined, isDefined }
