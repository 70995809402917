import { ClipBorder, Image } from "@artists/uikit"
import { Avatar, Chip, Link, Stack, Typography } from "@mui/material"
import type { FC } from "react"

import type { Winner } from "../../../../../../../api/hooks/challenge"
import { designerLink } from "../../../../../../../utils"
import { useActionStatuses } from "../../../../../../@shared"

interface Props {
	loading?: boolean
	winner: Winner
}
export const Hero: FC<Props> = ({ winner }) => {
	const action = useActionStatuses(winner.entry)
	const { userName, userImage } = winner.entry.user

	return (
		<Stack direction='row' spacing={2} alignItems='center'>
			{action || !userImage ? (
				<Avatar sx={{ width: 90, height: 90 }} />
			) : (
				<ClipBorder borderRadius='50%'>
					<Image
						src={userImage}
						style={{
							width: 90,
							height: 90,
							borderRadius: "50%",
						}}
					/>
				</ClipBorder>
			)}

			<Stack spacing={1}>
				{winner.isFirstTime && (
					<Chip
						size='small'
						color='primary'
						label='FIRST TIME WINNER'
						sx={{ alignSelf: "start" }}
					/>
				)}
				<Typography>
					{action ? (
						action.subtitle
					) : (
						<Link
							href={designerLink(userName)}
							target='_blank'
							underline='hover'
						>
							{userName}
						</Link>
					)}
				</Typography>
			</Stack>
		</Stack>
	)
}
