import { ArrowBackIosNewRounded } from "@mui/icons-material"
import { Button, Collapse as MuiCollapse, Typography } from "@mui/material"
import type { FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useToggle } from "react-use"

interface Props {
	in?: boolean
	label: string
	children: ReactNode
	mountOnEnter?: boolean
}
export const Collapse: FC<Props> = ({
	in: inProp = false,
	mountOnEnter,
	label,
	children,
}) => {
	const { t } = useTranslation("challenge")
	const [open, toggle] = useToggle(inProp)

	return (
		<>
			<Button
				disableRipple
				id='showDetails'
				onClick={toggle}
				sx={{
					textTransform: "none",
					"&:hover": { bgcolor: "transparent" },
				}}
			>
				<Typography
					mr={2}
					sx={{ textDecoration: "underline" }}
					fontWeight='bold'
					color='warning.dark'
				>
					{`${open ? t`hide` : t`view`} ${label}`}
				</Typography>

				<ArrowBackIosNewRounded
					sx={{
						transition: ({ transitions }) =>
							transitions.create("rotate"),
						rotate: `${open ? 90 : -90}deg`,
						fontSize: "1rem",
						color: "warning.dark",
					}}
				/>
			</Button>

			<MuiCollapse in={open} timeout='auto' mountOnEnter={mountOnEnter}>
				{children}
			</MuiCollapse>
		</>
	)
}
