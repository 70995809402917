import { Box, Skeleton, Typography } from "@mui/material"
import type { FC } from "react"

import type { Partners } from "../../../../../api/hooks/home"
import { Root } from "../../../../../utils"
import { randomTags } from "./consts"
import { Tag } from "./styles"

interface Props {
	loading?: boolean
	partners: Partners["regular"]
}

export const Tags: FC<Props> = ({ loading, partners }) => (
	<Box
		gap={1}
		m='auto'
		display='flex'
		maxWidth={620}
		flexWrap='wrap'
		sx={{ placeContent: "center" }}
	>
		{loading
			? randomTags.map((width, idx) => (
					<Skeleton
						key={idx}
						height={38}
						width={width}
						sx={{ transform: "none" }}
					/>
				))
			: partners.map(p => (
					<Tag
						key={p.id}
						to={Root.Challenge.buildPath({ slug: p.challengeSlug })}
						// state={Root.Challenge.buildState({
						// 	status: ChallengeStatusName.ResultsPublic
						// })}
					>
						<Typography variant='subtitle2'>{p.name}</Typography>
					</Tag>
				))}
	</Box>
)
