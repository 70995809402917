import { Link, Typography } from "@mui/material"
import type { FC } from "react"
import { Trans } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

interface Props {
	isManual: boolean
	partner: string
}
export const Info: FC<Props> = ({ isManual, partner }) => {
	if (!isManual) return null

	return (
		<Typography mt={3}>
			<Trans ns='challenge' i18nKey='info' values={{ partner }}>
				<b />
				<Link component={RouterLink} to='#showDetails' />
			</Trans>
		</Typography>
	)
}
