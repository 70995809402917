import { Box } from "@mui/material"
import type { FC } from "react"
import { useCallback, useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"

import { css } from "./sticky.styles"
import type { StickyProps } from "./sticky.types"

export const Sticky: FC<StickyProps> = ({
	withShadow = true,
	sx = {},
	...props
}) => {
	const ref = useRef<HTMLDivElement>()

	const { ref: viewRef, entry } = useInView({ threshold: 1 })

	const setRef = useCallback(
		(node: HTMLDivElement | undefined) => {
			ref.current = node
			viewRef(node)
		},
		[viewRef],
	)

	useEffect(() => {
		ref.current?.toggleAttribute(
			"stuck",
			(entry?.intersectionRatio ?? 1) < 1,
		)
	}, [entry?.intersectionRatio])

	return (
		<Box
			top={-1}
			zIndex={1}
			display='flex'
			position='sticky'
			ref={setRef}
			sx={theme => (withShadow ? css(theme, sx) : sx)}
			{...props}
		/>
	)
}
