import { ChallengeStatusName } from "@artists/gql/design-challenges"
import { Box } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import {
	type Challenge,
	ChallengeStatus,
} from "../../../../../api/hooks/challenge"
import { getTimes } from "../../../../../utils"
import { Time } from "../time"

interface Props {
	loading: boolean
	challenge?: Challenge
}
export const Times: FC<Props> = ({ loading, challenge }) => {
	const { t } = useTranslation("challenge")
	const times = getTimes({
		entriesOpen: challenge?.entriesOpenAt,
		entriesClose: challenge?.entriesCloseAt,
		votingOpens: challenge?.votingOpensAt,
		votingCloses: challenge?.votingClosesAt,
		results: challenge?.resultsPublicAt,
	})

	return (
		<Box
			gap={3}
			width='100%'
			display='grid'
			maxWidth={1120}
			justifyContent='center'
			gridTemplateColumns='repeat(auto-fit, minmax(320px, 1fr))'
		>
			<Time
				loading={loading}
				title={t`timeline.entries`}
				isPast={times.entriesClose.isPassed}
				data={[times.entriesOpen, times.entriesClose]}
				active={
					challenge?.status.current.name ===
					ChallengeStatusName.EntriesOpen
				}
			/>
			<Time
				loading={loading}
				title={t`timeline.voting`}
				isPast={times.votingCloses.isPassed}
				data={[times.votingOpens, times.votingCloses]}
				active={
					challenge?.status.current.name ===
					ChallengeStatus.VotingOpen
				}
			/>
			<Time
				loading={loading}
				data={[times.results]}
				title={t`timeline.results`}
				isPast={times.results.isPassed}
				active={
					challenge?.status.current.name ===
					ChallengeStatus.ResultsPublic
				}
			/>
		</Box>
	)
}
