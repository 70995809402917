import { AcceptableRepeat } from "@artists/gql/design-challenges"

export const REPEATS = {
	[AcceptableRepeat.Basic]: "basic",
	[AcceptableRepeat.HalfDrop]: "half-drop",
	[AcceptableRepeat.HalfBrick]: "half-brick",
	[AcceptableRepeat.Mirror]: "mirror",
	[AcceptableRepeat.Center]: "center",
}

export const getRepeatsText = (patterns: AcceptableRepeat[]) =>
	patterns
		.map(repeat => REPEATS[repeat].replace(/\b\w/g, l => l.toUpperCase()))
		.join(", ")
