import { Box, Divider, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Details } from "./details"
import { Images } from "./images"

export const Participate = () => {
	const { t } = useTranslation("home")

	return (
		<Stack
			id='why'
			mb={9}
			pt={5}
			px={3}
			gap={5}
			pb={7.5}
			bgcolor='paper.secondary'
			component='section'
			mx={{ xs: -3, md: -5 }}
			sx={{ scrollMarginTop: { xs: 190, md: 34 } }}
		>
			<Typography textAlign='center' variant='h4Serif' component='h2'>
				{t`participate.title`}
			</Typography>

			<Divider />

			<Box
				display='flex'
				justifyContent='center'
				alignItems='stretch'
				flexWrap='wrap'
				gap={5}
			>
				<Images />
				<Details />
			</Box>
		</Stack>
	)
}
