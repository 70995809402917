import { Sticky as AppSticky } from "@artists/uikit"

import { Nav } from "./nav"

export const Sticky = () => (
	<AppSticky
		p={1}
		mb={6}
		mx={{ xs: -3, md: -5 }}
		bgcolor='paper.secondary'
		justifyContent='center'
	>
		<Nav />
	</AppSticky>
)
