import { homeChallengesIds } from "@artists/challenges-testkit"
import {
	CardContent,
	CardMedia,
	CardSubTitle,
	CardTitle,
	ClipBorder,
} from "@artists/uikit"
import { Button } from "@mui/material"
import type { FC } from "react"
import { Link } from "react-router-dom"

import { Root } from "../../../../utils"
import { Time } from "../../time"
import type { ListItem } from "../list.types"

export const Item: FC<ListItem> = ({
	image,
	title,
	subtitle,
	status,
	slug,
}) => (
	<Button
		disableRipple
		component={Link}
		to={Root.Challenge.buildPath({ slug })}
		state={Root.Challenge.buildState({ status })}
		data-testid={homeChallengesIds.Challenge(title)}
		sx={{
			p: 0,
			width: "100%",
			display: "block",
			overflow: "hidden",
			borderRadius: "5px",
			textTransform: "none",
			boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
			"&:hover, &:focus": {
				bgcolor: "action.hover",
				"& .card-image": { transform: "scale(1.05)" },
			},
		}}
	>
		<ClipBorder>
			<CardMedia image={image} />

			<CardContent>
				<CardTitle component='h3'>{title}</CardTitle>
				<CardSubTitle noWrap={false}>
					{typeof subtitle === "string" ? (
						subtitle
					) : (
						<>
							{subtitle.label} <Time>{subtitle.time}</Time>
						</>
					)}
				</CardSubTitle>
			</CardContent>
		</ClipBorder>
	</Button>
)
