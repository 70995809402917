import type { FC } from "react"

import type { Challenge } from "../../../../api/hooks/challenge"
import { Hero as SharedHero } from "../../../@shared/hero"
import { Overlay } from "./overlay"

interface Props {
	loading: boolean
	challenge?: Challenge
}

export const Hero: FC<Props> = ({ loading, challenge }) => {
	const images = challenge && {
		xs: challenge.images.hero560x760,
		sm: challenge.images.hero800x640,
		md: challenge.images.hero1080x560,
		lg: challenge.images.hero1480x560,
		xl: challenge.images.hero1770x670,
	}

	return (
		<SharedHero
			images={images}
			overlaySx={{ minHeight: 300 }}
			overlay={<Overlay loading={loading} challenge={challenge} />}
			minImageHeights={{ xs: 650, sm: 600, md: 500, lg: 500, xl: 600 }}
		/>
	)
}
