import { Stack, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"

export const Description = () => {
	const { t } = useTranslation("home")

	return (
		<Stack
			textAlign='center'
			maxWidth={744}
			mx='auto'
			my={6}
			px={{ xs: 3, md: 0 }}
		>
			<Typography
				variant='h4Serif'
				component='h1'
				fontSize='2.25em'
				mb={3}
			>
				{t`title`}
			</Typography>
			<Typography>
				<Trans ns='home' i18nKey='subtitle'>
					<b />
				</Trans>
			</Typography>
		</Stack>
	)
}
