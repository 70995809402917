import { CalendarMonth } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { Challenge } from "../../../../api/hooks/challenge"
import { Times } from "./times"

interface Props {
	loading: boolean
	challenge?: Challenge
}
export const Timeline: FC<Props> = ({ loading, challenge }) => {
	const { t } = useTranslation("challenge")

	return (
		<Stack id='timeline' alignItems='center' spacing={4} mt={8}>
			<Stack direction='row' alignItems='center' spacing={2}>
				<CalendarMonth fontSize='large' />
				<Typography variant='h4Serif' component='h2'>
					{t("timeline.title")}
				</Typography>
			</Stack>

			<Times loading={loading} challenge={challenge} />
		</Stack>
	)
}
